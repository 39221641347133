<template>
  <canvas
    :id="id"
    :chart-options="options"
    :chart-data="data"
    width="400"
    height="400"
  ></canvas>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "BarGraphic",
  props: ["id", "data", "options"],

  data() {
    return { showing: false, chart: null };
  },
  watch: {
    data: {
      handler() {
        this.show();
      },
    },
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (this.chart) {
        this.chart.destroy();
      }
      const ctx = document.getElementById(this.id);
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.data,
        options: this.options,
      });
    },
  },
};
</script>
